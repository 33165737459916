import React from 'react';
import { useRouter } from 'next/router';
import ReactGA from 'react-ga';
import { GA_TRACKING_ID } from '@constants';

interface IRouterChangeTrackerProps {
  history: ReturnType<typeof useRouter>;
}

export function RouterChangeTracker({ history }: IRouterChangeTrackerProps): JSX.Element {
  ReactGA.initialize(GA_TRACKING_ID);
  ReactGA.set({ page: history.asPath });
  ReactGA.pageview(history.asPath);
  return <div />;
}
