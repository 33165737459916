import { useMemo } from 'react';
import { useBreakpoints } from '@cvent/carina/components/Breakpoints/BreakpointsProvider';

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const usePageStyles = ({ font }): any => {
  const { isL, isM, isS, isDefaultSize } = useBreakpoints();
  const isSmall = isDefaultSize || isS;
  return useMemo(
    () => ({
      incidentLabels: {
        fontSize: font.base.size['4'],
        fontWeight: font.base.weight[400],
        color: '#1A2026',
        fontFamily: font.base.fontFamily,
        fill: 'solid #1A2026'
      },
      incidentValueLabels: {
        fontSize: font.base.size['4'],
        fontWeight: font.base.weight[400],
        fontFamily: font.base.fontFamily,
        color: '#69717A',
        fontStyle: 'normal'
      },
      incidentHistoryListStyle: {
        justifyContent: 'center',
        padding: '0px 0px 0px 0px',
        margin: '0px 0px 0px 0px',
        width: 'auto',
        maxWidth: '',
        alignItems: 'center',
        borderRadius: '10px',
        boxShadow: '0px 2px 5px -2px',
        backgroundColor: '#FFFFFF'
      },
      incidentDurationLabel: {
        fontSize: font.base.size['2'],
        fontWeight: font.base.weight[400],
        fontFamily: font.base.fontFamily,
        color: '#69717A',
        fontStyle: 'normal'
      },
      incidentProductsSummaryCol: {
        width: isSmall || isM ? 1 : 1 / 3
      },
      parentTileStyle: {
        justifyContent: 'center',
        margin: isSmall || isM ? '20px 20px auto 20px' : '20px 0px auto 20px',
        padding: '10px 24px 0px 24px',
        width: isSmall || isM ? 'auto' : '703px',
        height: '100%',
        alignItems: 'center',
        borderRadius: '10px',
        backgroundColor: '#ffff'
      },
      productsImpactedTileStyle: {
        margin: isSmall || isM ? '55px 20px 0px 20px' : '20px 0px 0px 0px',
        padding: '10px 0px 25px 0px',
        justifyContent: 'center',
        width: isSmall ? '100%' : '70%',
        height: '100%',
        borderRadius: '10px',
        backgroundColor: '#ffff'
      },
      incidentNotesSubHeaders: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        height: '29px',
        fill: 'Solid #000000',
        display: 'block',
        width: '100%'
      },
      incidentNotesUl: {
        color: '#69717A',
        marginTop: '-5px'
      },
      incidentNotes: {
        color: '#69717A',
        fontSize: '16px',
        lineHeight: '24px',
        fill: 'solid #69717A'
      },
      incidentNotesHistoryDate: {
        color: '#69717A',
        fontSize: '16px',
        lineHeight: '24px',
        fill: 'solid #69717A'
      },
      incidentNotesContainer: {
        padding: '10px 24px 10px 24px',
        margin: isSmall || isM ? '40px 20px 20px 20px' : '20px 0px auto 0px',
        justifyContent: 'center',
        width: isSmall || isM ? 'auto' : '70%',
        height: 'auto',
        borderRadius: '10px',
        backgroundColor: '#ffff'
      },
      incidentNotesPanelDivStyle: {
        padding: '0px 24px 0px 24px',
        justifyContent: 'center',
        paddingBottom: '50px'
      },
      incidentInformationPanelStyle: {
        padding: '0px 0px 0px 24px',
        justifyContent: 'center'
      },
      basePageStyling: {
        homePageStyling: {
          width: '100%'
        },
        homePageFooterStyling: {
          width: '100%'
        },
        otherPageContentStyling: {
          height: '90%',
          width: '100%'
        },
        otherPageFooterStyling: {
          position: 'absolute',
          width: '100%'
        }
      },
      cookieBanner: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        display: 'flex',
        background: '#E9EDEF',
        padding: '34px 36px'
      },
      cookieBannerParent: {
        margin: 'auto',
        display: 'flex',
        flexDirection: isSmall ? 'column-reverse' : 'row-reverse'
      },
      cookieText: {
        color: '#3b4d54',
        lineHeight: font.lineHeight.prose,
        fontSize: font.base.size.s,
        fontWeight: font.base.weight.bold
      },
      cookieBannerButton: {
        position: 'relative'
      },
      buttonColCss: {
        offset: isS || isM || isDefaultSize || isL ? 10 : 0,
        marginTop: isS || isM || isDefaultSize || isL ? 10 : 0
      },
      rowStyle: {
        width: '100%'
      },
      productsPage: {
        responsiveProductDisplayColProps: {
          l: { width: 2.3 / 3 },
          xl: { width: 2.3 / 3 },
          s: { width: 2.3 / 3 },
          m: { width: 2 / 3 },
          flex: { display: 'flex' }
        },
        productDisplayStyling: {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: 'Rubik'
        },
        panelOpenBrandNameDivStyling: {
          maxWidth: '100%',
          width: '80%',
          display: 'block',
          margin: 'auto',
          justifyContent: 'center'
        },
        panelClosedBrandNameDivStyling: {
          width: isS || isM ? '90%' : '66%',
          display: 'block',
          justifyContent: 'center',
          margin: 'auto',
          maxWidth: '910px'
        },
        panelOpenCommonContentContainerStyling: {
          borderRadius: '50px',
          boxShadow: '0px 4px 16px -4px rgba(0, 0, 0, 0.1)',
          maxWidth: '910px',
          width: isSmall || isM ? '90%' : '80%',
          height: 'auto'
        },
        panelClosedCommonContentContainerStyling: {
          borderRadius: '50px',
          boxShadow: '0px 4px 16px -4px rgba(0, 0, 0, 0.1)',
          maxWidth: '910px',
          width: isS || isM ? '90%' : '66%',
          height: 'auto'
        },
        responsiveProductSeverityColProps: {
          width: 'fill',
          flex: { display: 'flex', justifyContent: 'right' }
        }
      },
      subsystemsPage: {
        panelOpenResponsiveFixedIncidentColProps: {
          l: { width: 2.5 / 3 },
          xl: { width: 2.5 / 3 },
          s: { width: 0.6 / 3 },
          m: { width: 2.5 / 3 },
          flex: { display: 'flex', justifyContent: 'flex-end' }
        },
        panelClosedResponsiveFixedIncidentColProps: {
          l: { width: 2.5 / 3 },
          xl: { width: 2.5 / 3 },
          s: { width: 2.5 / 3 },
          m: { width: 2.6 / 3 },
          flex: { display: 'flex', justifyContent: 'flex-end' }
        },
        responsiveStatusColProps: {
          width: 'content',
          padding: { end: 0 },
          flex: { display: 'flex', justifyContent: 'right' }
        },
        responsiveOperationalStatusColProps: {
          width: 'fill',
          offset: 0,
          padding: { end: 0 },
          flex: { display: 'flex', justifyContent: 'right' }
        },
        responsiveSubsystemDisplayNameColProps: {
          l: { width: 2.1 / 3 },
          xl: { width: 2.1 / 3 },
          s: { width: 2.1 / 3 },
          m: { width: 2.1 / 3 },
          flex: { display: 'flex', justifyContent: 'left' }
        },
        responsiveSubsystemDisplayNameColPropsOther: {
          l: { width: 1.9 / 3 },
          xl: { width: 1.9 / 3 },
          s: { width: 2.1 / 3 },
          m: { width: 2.1 / 3 },
          flex: { display: 'flex', justifyContent: 'left' }
        },
        subsystemListRowProps: {
          margin: { end: isS ? -8 : -0 },
          display: 'flex'
        }
      }
    }),
    [font, isL, isM, isS, isDefaultSize, isSmall]
  );
};
