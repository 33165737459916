import React, { ReactNode, useState } from 'react';
import { useQuery } from '@apollo/client';
import { NavigationProvider } from '@cvent/carina/components/Navigation/NavigationProvider';
import { BasePage } from '@components/BasePage';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';
import { GET_APP_NAVIGATION_QUERY } from '@cvent/planner-navigation/client';
import Head from 'next/head';
import { Brand, Datacenter } from '@typings/graph';
import CookieBanner from '@components/CookieBanner/CookieBanner';
import { parseCookies } from 'nookies';
import { checkCookieForData } from '@utils/cookieUtils';
import { RouterChangeTracker } from '@components/CookieBanner/RouterTracker';
import { CONSENT_COOKIE_NAME } from '@constants';
import { NavMetadata } from '@cvent/planner-navigation/types';

const LOG = LoggerFactory.create('Page');
export interface IPageProps {
  navMetadata: NavMetadata;
  pageTitle: string;
  brandsList?: Brand[];
  regionList?: Datacenter[];
  backgroundColor: string;
  children: ReactNode;
  redirectUrl?: string;
}

export default function Page({
  children,
  navMetadata,
  pageTitle,
  brandsList,
  regionList,
  backgroundColor
}: IPageProps): JSX.Element {
  const router = useRouter();
  const [loadTracker, setLoadTracker] = useState<boolean>(false);
  const cookies = parseCookies();
  const consentCookie = checkCookieForData(cookies);
  if (consentCookie && !loadTracker && consentCookie[CONSENT_COOKIE_NAME] === 'Accept') {
    setLoadTracker(true);
  }
  const handlers = {
    setSearchTerm: (term): void => {
      LOG.info('SEARCH TERM:', term);
    },
    submitFilters: (content): void => {
      LOG.info('FILTERS CONTENT', content);
    },
    onSearch: (term?: string): void => {
      LOG.info('onSearch', term);
      router.push(`/search?term=${encodeURIComponent(term)}`);
    }
  };
  const { loading, data } = useQuery(GET_APP_NAVIGATION_QUERY, {
    variables: {
      navMetadata
    }
  });

  if (loading) {
    // TODO: empty for now, discuss options with Carina team and UX.
    // implement getStaticProps or getServerSideProps on a page to avoid
    return <div />;
  }
  const nav = {
    ...data.navigation,
    ...handlers,
    Link
  };

  return (
    <div>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <NavigationProvider {...nav}>
        <BasePage brandsList={brandsList || []} regionList={regionList || []} backgroundColor={backgroundColor}>
          {children}
        </BasePage>
        {(!consentCookie || Object.keys(consentCookie).length === 0) && <CookieBanner />}
        {loadTracker && <RouterChangeTracker history={router} />}
      </NavigationProvider>
    </div>
  );
}
