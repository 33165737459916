import React from 'react';
import { SideNavigationHeader } from '@cvent/carina/components/Navigation/SideNavigationHeader';
import { useNavigation } from '@cvent/carina/components/Navigation/NavigationProvider';
import { SideNavigationLink } from '@cvent/carina/components/Navigation/SideNavigationLink';
import { SideNavigation as SideNav } from '@cvent/carina/components/Navigation/SideNavigation';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import { Brand, CurrentSeverityValues, Datacenter } from '@typings/graph';
import { Col } from '@cvent/carina/components/Col';
import { Row } from '@cvent/carina/components/Row';
import { useBreakpoints } from '@cvent/carina/components/Breakpoints';

import { DEGRADED_STATUS_TEXT, OPERATIONAL_STATUS_TEXT, OUTAGE_STATUS_TEXT } from '@constants';
import { CommonStatusLabel } from '@components/CommonStatusLabelComponent';
import { StatusIcons } from '@components/page/Home/Products/incidentStatusIcons';

interface ISeverityIconProps {
  regionNavValue: string;
  brandNavItem: string;
  brandList: Brand[];
  regionList: Datacenter[];
}

function SeverityIconForBrand({
  brandList,
  brandNavItem,
  regionList,
  regionNavValue
}: ISeverityIconProps): JSX.Element {
  const selectedRegion = regionList.find(region => region.displayName === regionNavValue);
  const currentBrand = brandList.find(
    brand =>
      brand.name === (brandNavItem || 'Event Marketing & Management') &&
      brand.datacenterId === Number(selectedRegion.id)
  );
  const selectedBrandSeverity = currentBrand.currentSeverity;
  return <StatusIcons severity={selectedBrandSeverity?.currentSeverity || CurrentSeverityValues.Operational} />;
}
function getCurrentRegionSeverity(regionNavValue, regionList): CurrentSeverityValues {
  return regionList.find(region => region.displayName === `${regionNavValue}`)?.currentSeverity?.currentSeverity;
}

interface ISeverityIconRegionProps {
  regionNavValue: string;
  regionList: Datacenter[];
}

function SeverityIconForRegion({ regionNavValue, regionList }: ISeverityIconRegionProps): JSX.Element {
  const currentRegionIncidentSeverity = getCurrentRegionSeverity(regionNavValue, regionList);
  return (
    <CommonStatusLabel
      incidentSeverity={currentRegionIncidentSeverity || CurrentSeverityValues.Operational}
      css={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}
    />
  );
}
interface IRegionSideNavDisplayProps {
  regionNavValue: string;
  regionList: Datacenter[];
}
function RegionSideNavDisplay({ regionNavValue, regionList }: IRegionSideNavDisplayProps): JSX.Element {
  const currentRegionSeverity =
    getCurrentRegionSeverity(regionNavValue, regionList) || CurrentSeverityValues.Operational;
  const { isS } = useBreakpoints();
  const regionTextWidth =
    // eslint-disable-next-line no-nested-ternary
    currentRegionSeverity.toLowerCase() === DEGRADED_STATUS_TEXT.toLowerCase()
      ? isS
        ? '51%'
        : '44.5%'
      : // eslint-disable-next-line no-nested-ternary
      currentRegionSeverity.toLowerCase() === OPERATIONAL_STATUS_TEXT.toLowerCase()
      ? isS
        ? '70%'
        : '64.5%'
      : // eslint-disable-next-line no-nested-ternary
      currentRegionSeverity.toLowerCase() === OUTAGE_STATUS_TEXT.toLowerCase()
      ? isS
        ? '75%'
        : '71.6%'
      : isS
      ? '65%'
      : '60%';

  return (
    <Row>
      <div
        css={{
          float: 'left',
          display: 'inline-block',
          width: regionTextWidth
        }}
      >
        <SideNavigationHeader
          // @ts-expect-error FIXME: carina typing
          text={<div style={{ overflowWrap: 'break-word', float: 'left', whiteSpace: 'normal' }}>{regionNavValue}</div>}
        />
      </div>
      <div css={{ marginLeft: '5%', justifyContent: 'right', alignSelf: 'center' }}>
        <SeverityIconForRegion regionNavValue={regionNavValue} regionList={regionList} />
      </div>
    </Row>
  );
}

export interface ISideNavigationProps {
  brandsList: Brand[];
  regionList: Datacenter[];
}

export function SideNavigation({ brandsList, regionList }: ISideNavigationProps): JSX.Element {
  const { localNav } = useNavigation();
  const router = useRouter();

  return (
    <SideNav isOpen>
      {localNav[0].items.map(topLevelNavItem => {
        if (topLevelNavItem.items.length <= 0) {
          return (
            <li>
              <div style={{ display: 'inline-block', width: '60%' }}>
                <SideNavigationHeader text={topLevelNavItem.title} />
              </div>
              <div style={{ display: 'inline-block', width: '40%' }}>
                <SeverityIconForRegion regionNavValue={topLevelNavItem.title} regionList={regionList} />
              </div>
            </li>
          );
        }
        return (
          <>
            <RegionSideNavDisplay regionNavValue={topLevelNavItem.title} regionList={regionList} />
            {topLevelNavItem.items?.map(subItem => (
              <SideNavigationLink
                key={`${subItem.title}_${subItem.id}`}
                isCurrent={subItem.url.href === router.asPath}
                isDisabled={subItem.disabled || false}
                disabledText="This feature has not been enabled for this event. You can manage your event features in the configuration area."
              >
                <NextLink href={subItem.url?.href}>
                  <Row>
                    <Col width={6.6 / 8}>
                      <a href={subItem.url?.href}>{subItem.title}</a>
                    </Col>
                    <Col width={1.3 / 8} flex={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                      <SeverityIconForBrand
                        regionNavValue={topLevelNavItem.title}
                        brandNavItem={subItem.title}
                        brandList={brandsList}
                        regionList={regionList}
                      />
                    </Col>
                  </Row>
                </NextLink>
              </SideNavigationLink>
            ))}
          </>
        );
      })}
    </SideNav>
  );
}
