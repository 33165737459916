import { useContext } from 'react';
import ThemeContext from '@cvent/carina/components/ThemeProvider/ThemeContext';

const emptyParameters = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export default function useStyle(useBuildStyle, additionalParameters: any = emptyParameters): any {
  const theme = useContext(ThemeContext);
  return useBuildStyle(theme, additionalParameters);
}
