import React, { Fragment } from 'react';
import { On } from '@cvent/carina/components/On';
import { Col } from '@cvent/carina/components/Col';
import { Row } from '@cvent/carina/components/Row';
import useTheme from '@hooks/useTheme';
import { TextLink } from '@cvent/carina/components/TextLink';
import { useRouter } from 'next/router';
import { cventFooterDCID } from '@dataCventIds';
import Image from 'next/image';

interface FooterStyle {
  textLink: Record<string, unknown>;
  logo: Record<string, unknown>;
}

function useStyles(): FooterStyle {
  const { font, backgroundColor } = useTheme();

  return {
    textLink: {
      fontFamily: font.base.family.Rubik,
      color: font.color.soft,
      fontSize: font.base.size.xs,
      fontWeight: font.base.weight.light,
      paddingLeft: 4,
      paddingRight: 4
    },
    logo: {
      height: 14,
      width: 72,
      background: backgroundColor.surface
    }
  };
}

export function Footer(): JSX.Element {
  const { textLink } = useStyles();
  const year: number = new Date().getFullYear();

  const router = useRouter();

  return (
    <>
      <On renderIf={({ isS, isM }) => isS || isM}>
        <>
          <Row justifyContent="center" testID="as-status-site-footer">
            <span
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Col width="content">
                <span style={textLink}>{`© 2000-${year} Cvent, Inc. All Rights Reserved`}</span>
              </Col>
            </span>
          </Row>
          <Row justifyContent="center">
            <Col width="content">
              <TextLink
                href="https://www.cvent.com/en/product-terms-of-use"
                neutral
                target="_blank"
                rel="noopener noreferrer"
                style={textLink}
                testID={cventFooterDCID.termsAndConditions}
              >
                Terms of Use
              </TextLink>
              <span style={textLink}>&nbsp;|&nbsp;</span>
              <TextLink
                href="https://www.cvent.com/cvent-global-privacy-policy"
                neutral
                target="_blank"
                rel="noopener noreferrer"
                style={textLink}
                testID={cventFooterDCID.privacyPolicy}
              >
                Privacy Policy
              </TextLink>
            </Col>
          </Row>
        </>
      </On>
      <On renderIf={({ isS, isM }) => !(isS || isM)}>
        <Row testID="as-status-site-footer" justifyContent="center" style={{ width: '100%', maxWidth: '100%' }}>
          <Col width="content">
            <TextLink onClick={() => router.push('https://www.cvent.com')}>
              <Image src="/cvent-footer.png" height={14} width={72} />
            </TextLink>
          </Col>
          <Col width="content">
            <span style={textLink}>{`© 2000-${year} Cvent, Inc. All Rights Reserved`}</span>
          </Col>
          <Col width="fill" flex={{ display: 'flex', justifyContent: 'right' }}>
            <TextLink
              href="https://www.cvent.com/en/product-terms-of-use"
              neutral
              target="_blank"
              rel="noopener noreferrer"
              style={textLink}
            >
              Terms of Use
            </TextLink>
            <span style={textLink}>&nbsp;|&nbsp;</span>
            <TextLink
              href="https://www.cvent.com/cvent-global-privacy-policy"
              neutral
              target="_blank"
              rel="noopener noreferrer"
              style={textLink}
            >
              Privacy Policy
            </TextLink>
          </Col>
        </Row>
      </On>
    </>
  );
}
