import React from 'react';
import { StatusLabel } from '@cvent/carina/components/StatusLabel';
import {
  OPERATIONAL_STATUS_TEXT,
  INFORMATION_STATUS_TEXT,
  DEGRADED_STATUS_TEXT,
  PARTIAL_OUTAGE_STATUS_TEXT,
  OUTAGE_STATUS_TEXT
} from '@constants';
import { CurrentSeverityValues } from '@typings/graph';

type incidentVariant = {
  variantColor: 'positive' | 'negative' | 'transitional' | 'neutral';
  variantDisplayLabel: string;
};

function getVariantForLabel(incidentSeverity, impactedSubsystemsCount = 0): incidentVariant {
  if (incidentSeverity === CurrentSeverityValues.Information) {
    return {
      variantColor: 'neutral',
      variantDisplayLabel: impactedSubsystemsCount > 0 ? `${impactedSubsystemsCount} affected` : INFORMATION_STATUS_TEXT
    };
  }
  if (
    (incidentSeverity === CurrentSeverityValues.DegradedPerformance ||
      incidentSeverity === CurrentSeverityValues.PartialOutage) &&
    impactedSubsystemsCount === 0
  ) {
    return {
      variantColor: 'transitional',
      variantDisplayLabel:
        incidentSeverity.toLowerCase() === CurrentSeverityValues.DegradedPerformance.toLowerCase()
          ? DEGRADED_STATUS_TEXT
          : PARTIAL_OUTAGE_STATUS_TEXT
    };
  }
  if (
    (incidentSeverity === CurrentSeverityValues.DegradedPerformance ||
      incidentSeverity === CurrentSeverityValues.PartialOutage) &&
    impactedSubsystemsCount > 0
  ) {
    return {
      variantColor: 'transitional',
      variantDisplayLabel: `${impactedSubsystemsCount} affected`
    };
  }
  if (incidentSeverity === CurrentSeverityValues.Outage) {
    return {
      variantColor: 'negative',
      variantDisplayLabel: impactedSubsystemsCount > 0 ? `${impactedSubsystemsCount} affected` : OUTAGE_STATUS_TEXT
    };
  }
  return {
    variantColor: 'positive',
    variantDisplayLabel: impactedSubsystemsCount > 0 ? `${impactedSubsystemsCount} affected` : OPERATIONAL_STATUS_TEXT
  };
}

export interface ICommonStatusLabelProps {
  incidentSeverity: CurrentSeverityValues;
  impactedSubsystemsCount?: number;
}
export function CommonStatusLabel({
  incidentSeverity,
  impactedSubsystemsCount = 0
}: ICommonStatusLabelProps): JSX.Element {
  const variant = getVariantForLabel(incidentSeverity, impactedSubsystemsCount);
  return <StatusLabel variant={variant.variantColor}>{variant.variantDisplayLabel}</StatusLabel>;
}
