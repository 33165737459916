import React, { useState } from 'react';
import Button from '@cvent/carina/components/Button';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { TextLink } from '@cvent/carina/components/TextLink';
import { Col } from '@cvent/carina/components/Col';
import { Row } from '@cvent/carina/components/Row';
import { COOKIE_BANNER_TEXT } from '@constants';
import { setConsentCookieValue } from '@utils/cookieUtils';
import useStyle from '@hooks/useThemeHelper';
import { usePageStyles } from '../styles';

function onCookieAcceptorDeny(value: string): void {
  setConsentCookieValue(value);
}

export default function CookieBanner(): JSX.Element {
  const [cookieConsent, setCookieConsent] = useState<boolean>(false);
  const { cookieBanner, cookieBannerParent, cookieText, cookieBannerButton, buttonColCss, rowStyle } =
    useStyle(usePageStyles);

  return (
    <div>
      {!cookieConsent && (
        <div {...injectTestId('cookie-banner')} css={cookieBanner}>
          <div css={cookieBannerParent}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Row css={rowStyle}>
                <Col width={1 / 2} offset={buttonColCss.offset} flex={{ display: 'flex' }}>
                  <Button
                    css={cookieBannerButton}
                    appearance="filled"
                    text="Accept"
                    aria-label="Accept"
                    onClick={(): void => {
                      onCookieAcceptorDeny('Accept');
                      setCookieConsent(true);
                    }}
                    isBlock
                    active
                    size="l"
                    disabled={false}
                  />
                </Col>
                <Col
                  width={1 / 2}
                  offset={buttonColCss.offset}
                  css={{ marginTop: buttonColCss.marginTop }}
                  flex={{ display: 'flex' }}
                >
                  <Button
                    css={cookieBannerButton}
                    appearance="filled"
                    text="Deny"
                    aria-label="Deny"
                    onClick={(): void => {
                      onCookieAcceptorDeny('Deny');
                      setCookieConsent(true);
                    }}
                    isBlock
                    active
                    size="l"
                    disabled={false}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <p css={cookieText}>{COOKIE_BANNER_TEXT}</p>
              <div css={{ margin: '8px 0px' }}>
                <TextLink
                  css={{ position: 'relative' }}
                  href="https://www.cvent.com/en/cvent-global-privacy-policy#cookies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </TextLink>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
