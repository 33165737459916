import React, { useState, useRef } from 'react';
import { Form, Textbox } from '@cvent/carina/components/Forms';
import { Col } from '@cvent/carina/components/Col';
import { Row } from '@cvent/carina/components/Row';
import Button from '@cvent/carina/components/Button';
import { FormElement } from '@cvent/carina/components/FormElement';
import { usePerformEmailVerificationMutation, PerformEmailVerificationMutationVariables } from '@typings/graph';

interface IEmailPopupProps {
  setIsEmailPopupOpen: (boolean) => void;
}

export function EmailPopup({ setIsEmailPopupOpen }: IEmailPopupProps): JSX.Element {
  const userEmailRef = useRef(undefined);
  const [showVerifyText, setShowVerifyText] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);
  const [emptyEmailWarning, setEmptyEmailWarning] = useState(false);

  const [performEmailVerification] = usePerformEmailVerificationMutation();

  const handleOnFormSubmit = async () => {
    if (userEmailRef.current.value.trim() === '') {
      setEmptyEmailWarning(true);
      return;
    }
    // check DB record
    const queryVariables: PerformEmailVerificationMutationVariables = {
      emailAddress: userEmailRef.current.value
    };

    const emailVerificationResponse = await performEmailVerification({
      variables: queryVariables
    });

    if (emailVerificationResponse?.data?.performEmailVerification?.validation) {
      setFormSubmitError(true);
    } else {
      setShowVerifyText(true);
    }
  };

  return (
    <Form onSubmit={handleOnFormSubmit}>
      <Form.Section>
        <Row>
          <Col width={1 / 8} />
          <Col width={3 / 4} flex={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h3>Verify User Settings</h3>
          </Col>
          <Col width={1 / 8} />
        </Row>
        {showVerifyText ? (
          <div>
            <Row>
              <Col flex={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FormElement.Label
                  label="A verification email has been sent to your email address. Please click the link in your email to continue."
                  labelFor="verify"
                />
              </Col>
            </Row>
            <Row>
              <Col
                style={{ marginTop: '10px' }}
                flex={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Button
                  type="button"
                  appearance="filled"
                  text="OK"
                  onClick={() => {
                    setIsEmailPopupOpen(false);
                  }}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            {formSubmitError ? (
              <div>
                <Row>
                  <Col flex={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <FormElement.Label
                      label="Something went wrong while trying to send the verfication email. Please check the email address or reach out to Cvent Support if the issue persists."
                      labelFor="error"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{ marginTop: '10px' }}
                    flex={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Button
                      type="button"
                      appearance="filled"
                      text="OK"
                      onClick={() => {
                        setIsEmailPopupOpen(false);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                {emptyEmailWarning && (
                  <Row>
                    <Col width={1 / 8} />
                    <Col
                      width={3 / 4}
                      style={{ marginBottom: '5px' }}
                      flex={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}
                    >
                      <FormElement.Label required label="Email address is required" labelFor="error" />
                    </Col>
                    <Col width={1 / 8} />
                  </Row>
                )}
                <Row>
                  <Col width={1 / 8} />
                  <Col
                    width={3 / 4}
                    flex={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Textbox id="email" forwardedRef={userEmailRef} label="Verify Email Address" name="email" />
                  </Col>
                  <Col width={1 / 8} />
                </Row>
                <Row>
                  <Col width={1 / 8} />
                  <Col
                    width={3 / 8}
                    flex={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right'
                    }}
                  >
                    <Button type="submit" text="Submit" appearance="filled" />
                  </Col>
                  <Col
                    width={3 / 8}
                    flex={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'left'
                    }}
                  >
                    <Button
                      type="button"
                      text="Cancel"
                      onClick={() => {
                        setIsEmailPopupOpen(false);
                      }}
                    />
                  </Col>
                  <Col width={1 / 4} />
                </Row>
              </div>
            )}
          </div>
        )}
      </Form.Section>
    </Form>
  );
}
