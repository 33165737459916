import { useContext } from 'react';
import ThemeContext from '@cvent/carina/components/ThemeProvider/ThemeContext';

/**
 * Simple hook for getting theme data from ThemeContext
 * https://carina-dev.core.cvent.org/components/breakpoints
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useTheme = (): any => useContext(ThemeContext);

export default useTheme;
