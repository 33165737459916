import React from 'react';
import { LoadingSpinner } from '@cvent/carina/components/LoadingSpinner';

export function LoadingPage(): JSX.Element {
  return (
    <div
      css={{
        display: 'grid',
        placeItems: 'center',
        minHeight: '100%',
        minWidth: '100%'
      }}
    >
      <LoadingSpinner size="m" />
    </div>
  );
}
