import React, { CSSProperties, ReactNode } from 'react';

/**
 * This component defines a container that will apply styles to the content area of a page.
 * We want to apply 1140px max width with equal margins for our content pages, but some pages need to have content
 * that spans the full width (i.e. the home page welcome header image), so we are not doing this at a higher
 * component in the tree, and instead using this component where ever we want to apply these styles.
 *
 * @constructor
 */

export type CommonContentContainerProps = React.PropsWithChildren & {
  width: string;
  margin?: string;
  children: ReactNode;
  styling: CSSProperties;
};
export function CommonContentContainer({ children, width, margin, styling }: CommonContentContainerProps): JSX.Element {
  const css = {
    width,
    maxWidth: styling.maxWidth ? styling.maxWidth : '1140px',
    margin: margin || 'auto',
    ...styling
  };
  return <div css={css}>{children}</div>;
}
