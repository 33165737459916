import React, { useState } from 'react';
import { Divider } from '@cvent/carina/components/Navigation/Divider';
import { ProductTitle } from '@cvent/carina/components/Navigation/ProductTitle';
import { TitleBar } from '@cvent/carina/components/Navigation/TitleBar';
import { PageTitle } from '@cvent/carina/components/Navigation/PageTitle';
import { GlobalNavigationLink as GlobalNavLink, FlyoutMenu, FlyoutMenuItem } from '@cvent/carina/components/Navigation';
import { Logo } from '@cvent/carina/components/Navigation/Logo';
import { TopNavigation as TopNav } from '@cvent/carina/components/Navigation/TopNavigation';
import { useNavigation } from '@cvent/carina/components/Navigation/NavigationProvider';
import HelpCircleIcon from '@cvent/carina/components/Icon/HelpCircle';
import MenuIcon from '@cvent/carina/components/Icon/Menu';
import UserIcon from '@cvent/carina/components/Icon/User';
import { useBreakpoints } from '@cvent/carina/components/Breakpoints/BreakpointsProvider';
import { OnWhen } from '@cvent/carina/components/OnWhen';
import { Col } from '@cvent/carina/components/Col';
import { Row } from '@cvent/carina/components/Row';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import Button from '@cvent/carina/components/Button';
import Modal from '@cvent/carina/components/Modal';
import { parseCookies } from 'nookies';
import { checkUserCookieForData, checkCookieForData } from '@utils/cookieUtils';
import {
  INCIDENT_TOP_NAVIGATION_VALUE,
  SUBSCRIBER_TOP_NAVIGATION_VALUE,
  USER_COOKIE_NAME,
  CONSENT_COOKIE_NAME
} from '@constants';
import { EmailPopup } from './EmailPopup';

type topNavProps = {
  hasLocalNav: boolean;
  isLocalNavOpen: boolean;
  toggleNav: () => void;
};

export function TopNavigation({ hasLocalNav, toggleNav, isLocalNavOpen }: topNavProps): JSX.Element {
  const { logo, appSwitcher, globalNav, page } = useNavigation();
  const { isL, isM, isS, isDefaultSize } = useBreakpoints();
  const router = useRouter();

  const appSwitcherData = [...appSwitcher.items];
  if (isS || isM || isDefaultSize) {
    appSwitcherData.push(...globalNav);
  }

  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);

  const userProfileClickHandler = () => {
    const cookies = parseCookies();
    const consentCookie = checkCookieForData(cookies);

    let userId: string;
    if (consentCookie && consentCookie[CONSENT_COOKIE_NAME] === 'Accept') {
      const userCookie = checkUserCookieForData(cookies);
      userId = userCookie[USER_COOKIE_NAME];
    }

    if (!userId || userId === 'undefined') {
      setIsEmailPopupOpen(true);
    } else {
      router.push(`/subscriber/${userId}`);
    }
  };

  return (
    <>
      <TopNav>
        <div css={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%' }}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              maxWidth: isS || isM || isDefaultSize ? '70%' : '40%'
            }}
          >
            <Logo
              logo={{ ...logo, url: 'https://www.cvent.com/' }}
              // eslint-disable-next-line react/no-unstable-nested-components
              Link={({ item, children }): JSX.Element => (
                <NextLink href={item.url} passHref>
                  {children}
                </NextLink>
              )}
            />
            <Divider />
            <ProductTitle title="STATUS" />
          </div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: isS ? 'right' : 'center',
              width: isS || isDefaultSize ? '40%' : '50%',
              paddingLeft: isS || isM || isDefaultSize ? '10px' : '0px'
            }}
          >
            <OnWhen m l xl>
              <ul
                role="menu"
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  transform: `translate(${isL ? -50 : -100}px, 0)`
                }}
              >
                {globalNav.map(
                  /* eslint-disable-next-line array-callback-return,consistent-return */
                  item => {
                    if (item?.url?.href) {
                      return (
                        <GlobalNavLink key={item.title}>
                          <NextLink href={item.url.href} passHref>
                            {item.title}
                          </NextLink>
                        </GlobalNavLink>
                      );
                    }
                  }
                )}
              </ul>
            </OnWhen>
            <OnWhen s>
              <FlyoutMenu accessibilityLabel="pages menu" icon="PagesIcon">
                {
                  closeMenu =>
                    globalNav.map((d, i) => (
                      <FlyoutMenuItem>
                        <a
                          href={d.url.href}
                          role="menuitem"
                          onBlur={() => {
                            if (i === globalNav.length - 1) {
                              closeMenu();
                            }
                          }}
                        >
                          {d.title}
                        </a>
                      </FlyoutMenuItem>
                    )) /* eslint-disable-next-line react/jsx-curly-newline */
                }
              </FlyoutMenu>
            </OnWhen>
          </div>
          <Modal isOpen={isEmailPopupOpen} onDismiss={() => setIsEmailPopupOpen(false)} portal>
            <div style={{ margin: '-6px 10px 21px 10px' }}>
              <EmailPopup setIsEmailPopupOpen={setIsEmailPopupOpen} />
            </div>
          </Modal>
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: isS || isM || isDefaultSize ? '20%' : '40%'
            }}
          >
            <Button
              disabled={false}
              icon={UserIcon}
              size="l"
              appearance="ghost"
              variant="neutral"
              onClick={userProfileClickHandler}
            />
            <Button
              disabled={false}
              icon={HelpCircleIcon}
              size="l"
              appearance="ghost"
              variant="neutral"
              onClick={() => {
                router.push('https://community.cvent.com/home');
              }}
            />
          </div>
        </div>
      </TopNav>
      {(page === INCIDENT_TOP_NAVIGATION_VALUE || page === SUBSCRIBER_TOP_NAVIGATION_VALUE) && (
        <TitleBar>
          <Row>
            <Col
              width="fill"
              flex={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              <PageTitle title={page} />
            </Col>
          </Row>
        </TitleBar>
      )}
      {page !== INCIDENT_TOP_NAVIGATION_VALUE && page !== SUBSCRIBER_TOP_NAVIGATION_VALUE && (
        <OnWhen s defaultSize>
          <TitleBar>
            <Row>
              <Col
                width="fill"
                flex={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                {hasLocalNav && (
                  <Button
                    onClick={toggleNav}
                    appearance="ghost"
                    variant="neutral"
                    accessibilityLabel="toggle local navigation"
                    onKeyPress={(e): void => {
                      if (e.key === 'Enter') toggleNav();
                    }}
                    /* we are updating the typing on icons exported from carina-icon to address the issue */
                    icon={MenuIcon}
                    aria-expanded={isLocalNavOpen}
                  />
                )}
              </Col>
            </Row>
          </TitleBar>
        </OnWhen>
      )}
    </>
  );
}
