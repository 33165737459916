import React, { ReactNode, useState } from 'react';
import { ScrollView } from '@cvent/carina/components/Templates/ScrollView';
import { TemplatePanel as Panel } from '@cvent/carina/components/Templates/TemplatePanel';
import { Header } from '@cvent/carina/components/Templates/Header';
import { Body } from '@cvent/carina/components/Templates/Body';
import { Main } from '@cvent/carina/components/Templates/Main';
import { ContentArea } from '@cvent/carina/components/Templates/ContentArea';
import { Page as TemplatePage } from '@cvent/carina/components/Templates/Page';
import { useNavigation } from '@cvent/carina/components/Navigation/NavigationProvider';
import { Brand, Datacenter } from '@typings/graph';
import { Footer } from '@components/Static';
import { Row } from '@cvent/carina/components/Row';
import { OnWhen } from '@cvent/carina/components/OnWhen';
import { SideNavigation } from './SideNavigation';
import { TopNavigation } from './TopNavigation';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useStyle = () => ({
  basePageStyling: {
    homePageStyling: {
      width: '100%'
    },
    homePageFooterStyling: {
      width: '100%',
      backgroundColor: '#F7F8F9'
    },
    otherPageContentStyling: {
      width: '100%'
    },
    otherPageFooterStyling: {
      width: '100%',
      backgroundColor: '#F7F8F9'
    }
  }
});

export interface IBasePageProps {
  backgroundColor: string;
  regionList?: Datacenter[];
  brandsList?: Brand[];
  children: ReactNode;
}

export function BasePage({ children, brandsList, regionList, backgroundColor }: IBasePageProps): JSX.Element {
  const [isLocalNavOpen, setLocalNavOpen] = useState(true);
  const { localNav, page } = useNavigation();
  const { basePageStyling } = useStyle();
  const contentStyling =
    page === 'Incident Summary' || page === 'Subscriber Information'
      ? basePageStyling.otherPageContentStyling
      : basePageStyling.homePageStyling;
  const footerStyling =
    page === 'Incident Summary' || page === 'Subscriber Information'
      ? basePageStyling.otherPageFooterStyling
      : basePageStyling.homePageFooterStyling;
  return (
    <TemplatePage>
      <Main as="div">
        <Header>
          <OnWhen s>
            <TopNavigation
              hasLocalNav={localNav.length > 0}
              toggleNav={(): void => setLocalNavOpen(curr => !curr)}
              isLocalNavOpen={isLocalNavOpen}
            />
          </OnWhen>
          <OnWhen m l xl>
            <TopNavigation
              hasLocalNav={localNav.length > 0}
              toggleNav={(): void => setLocalNavOpen(true)}
              isLocalNavOpen
            />
          </OnWhen>
        </Header>
        <Body>
          <OnWhen s>
            {localNav.length > 0 && isLocalNavOpen && (
              <Panel>
                <SideNavigation brandsList={brandsList} regionList={regionList} />
              </Panel>
            )}
          </OnWhen>
          <OnWhen m l xl>
            {localNav.length > 0 && (
              <Panel>
                <SideNavigation brandsList={brandsList} regionList={regionList} />
              </Panel>
            )}
          </OnWhen>
          <ContentArea>
            <ScrollView>
              <div style={contentStyling} id="mainBodyDiv">
                <Row justifyContent="center">
                  <div
                    css={{
                      flexGrow: 1,
                      backgroundColor,
                      flexShrink: 1
                    }}
                  >
                    {children}
                  </div>
                </Row>
              </div>
              <OnWhen s m>
                <Footer />
              </OnWhen>
            </ScrollView>
            <OnWhen xl l>
              <div css={footerStyling}>
                <Footer />
              </div>
            </OnWhen>
          </ContentArea>
        </Body>
      </Main>
    </TemplatePage>
  );
}
