export const incidentPageDCID = {
  incidentIdLabel: 'incident-id-label',
  incidentIdValue: 'incident-id-value',
  incidentTitleLabel: 'incident-title-label',
  incidentTitle: 'incident-title',
  statusLabel: 'incident-status-label',
  statusValue: 'incident-status-value',
  datacenterLabel: 'incident-datacenter-label',
  datacenterValue: 'incident-datacenter-value',
  brandListAccordionLabel: 'incident-brand-list-label',
  brandList: 'incident-brand-list',
  productList: 'incident-product-list',
  subsystemList: 'incident-subsystem-list',
  impactCategoryLabel: 'impact-category-label',
  impactCategoryValue: 'impact-category-value',
  startDateLabel: 'start-date-label',
  startDateValue: 'start-date-value',
  endDateLabel: 'end-date-label',
  endDateValue: 'end-date-value',
  lastModifiedDateLabel: 'last-modified-label',
  lastModifiedDateValue: 'last-modified-value',
  pastUpdatesLabel: 'past-update-label',
  pastUpdatesValue: 'past-update-value'
};

export const subscriberPageDCID = {
  subscriberIdLabel: 'subscriber-id-label',
  emailAddressLabel: 'email-address-label',
  emailAddressValue: 'email-address-value',
  regionListLabel: 'region-list-label',
  regionListCheckBox: 'region-list-checkBox',
  regionListCheckBoxGroup: 'region-list-checkBoxGroup',
  brandListAccordionLabel: 'brand-list-label',
  brandListCheckBox: 'subscriber-brand-list-checkbox',
  brandListCheckBoxGroup: 'subsccriber-brand-list-checkboxgroup',
  cancelButton: 'cancel-button',
  unSubscribeButton: 'unsubscribe-button',
  submitSubscribeButton: 'submit-button',
  regionListValidation: 'region-list-checkBoxGroup-messages-validation-error-0',
  pageAlert: 'pageAlert'
};

export const LoadingSpinnerPageDCID = 'loading-spinner';
export const validationDetailsDCID = 'validation-details';

export const cventFooterDCID = {
  privacyPolicy: 'app-footer-privacy-policy',
  termsAndConditions: 'app-footer-terms-and-conditions',
  appFooter: 'app-footer',
  footerCopyRight: 'app-footer-copyright'
};

export const homePageDCID = {
  dateTable: 'home-page-date-table',
  subsystemTable: 'home-page-subsystem-table'
};
export const panelDCID = 'home-page-incident-panel';
export const panelDismissButtonDCID = 'home-page-incident-panel-dismiss';
