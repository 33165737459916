import { NavMetadata } from '@cvent/planner-navigation/types';

export function useGetHomePageNavMetaData(url = '', staticRouteId = '', region = '', brand = ''): NavMetadata {
  const topParentIdValue = region === 'NA' || region === 'EUR' ? 'mainLocalNav' : '';
  return {
    topParentId: topParentIdValue,
    url,
    staticRouteId,
    pathParams: [
      { key: 'region', value: region },
      { key: 'brand', value: brand }
    ]
  };
}
