import React from 'react';
import CheckIcon from '@cvent/carina/components/Icon/Check';
import XIcon from '@cvent/carina/components/Icon/X';
import InfoIcon from '@cvent/carina/components/Icon/Info';
import AlertTriangleIcon from '@cvent/carina/components/Icon/AlertTriangle';
import { CurrentSeverityValues } from '@typings/graph';
import {
  OUTAGE_COLOR,
  PARTIAL_OUTAGE_COLOR,
  DEGRADED_PERFORMANCE_COLOR,
  INFORMATIONAL_COLOR,
  OPERATIONAL_COLOR,
  PARTIAL_OUTAGE_STATUS_TEXT,
  DEGRADED_STATUS_TEXT,
  INFORMATION_STATUS_TEXT,
  OUTAGE_STATUS_TEXT,
  OPERATIONAL_STATUS_TEXT
} from '@constants';

export interface IIncidentStatusIconProps {
  severity?: CurrentSeverityValues;
}

export function StatusIcons({ severity }: IIncidentStatusIconProps): JSX.Element {
  switch (severity) {
    case CurrentSeverityValues.PartialOutage:
      return <AlertTriangleIcon color={PARTIAL_OUTAGE_COLOR} size="m" />;
    case CurrentSeverityValues.DegradedPerformance:
      return <AlertTriangleIcon size="m" color={DEGRADED_PERFORMANCE_COLOR} />;
    case CurrentSeverityValues.Information:
      return <InfoIcon size="m" color={INFORMATIONAL_COLOR} />;
    case CurrentSeverityValues.Outage:
      return <XIcon size="m" color={OUTAGE_COLOR} />;
    default:
      return <CheckIcon size="m" color={OPERATIONAL_COLOR} />;
  }
}

export function getStatusName(severity: string): string {
  switch (severity) {
    case CurrentSeverityValues.PartialOutage:
      return PARTIAL_OUTAGE_STATUS_TEXT;
    case CurrentSeverityValues.DegradedPerformance:
      return DEGRADED_STATUS_TEXT;
    case CurrentSeverityValues.Information:
      return INFORMATION_STATUS_TEXT;
    case CurrentSeverityValues.Outage:
      return OUTAGE_STATUS_TEXT;
    default:
      return OPERATIONAL_STATUS_TEXT;
  }
}
